import React, { useState } from "react";
import {
  FaEnvelope,
  FaFacebookF,
  FaInstagram,
  FaLinkedinIn,
  FaSpotify,
  FaFileAlt,
} from "react-icons/fa";
import "./ContactPage.css";

function ContactPage() {
  const [hovered, setHovered] = useState(null);
  const [copyMessage, setCopyMessage] = useState(""); // State for copy message

  const handleMouseEnter = (event, index) => {
    setHovered(index);
  };

  const handleMouseLeave = () => {
    setHovered(null);
  };

  const handleEmailClick = () => {
    const email = "nagathota.stevenson@gmail.com";
    window.location.href = `mailto:${email}`;
    setCopyMessage("Email client opened!"); // Set the message
    setTimeout(() => setCopyMessage(""), 2000); // Clear the message after 2 seconds
  };

  const handleFacebookClick = () => {
    console.log("clicked");
    const facebookUrl = "https://www.facebook.com/stevenson.nagathota"; // Replace with your Facebook profile URL
    window.open(facebookUrl, "_blank");
  };

  const handleInstagramClick = () => {
    const instagramUrl = "https://www.instagram.com/superboy_steve/"; // Replace with your Instagram profile URL
    window.open(instagramUrl, "_blank");
  };

  const handleLinkedinClick = () => {
    const linkedinUrl = "https://www.linkedin.com/in/stevenson-nagathota-b83678183/"; // Replace with your LinkedIn profile URL
    window.open(linkedinUrl, "_blank");
  };

  const handleSpotifyClick = () => {
    const spotifyUrl = "https://open.spotify.com/artist/0nVMi9CGb8l9BsR6uMkNW6"; // Replace with your Spotify profile URL
    window.open(spotifyUrl, "_blank");
  };

  const handleResumeClick = () => {
    const resumeUrl = "https://drive.google.com/file/d/1BJQQgXv3X_WdT4ZhfNkjlsjQ-c1A1x6O/view?usp=sharing"; // Replace with the path to your resume
    window.open(resumeUrl, "_blank");
  };

  const buttonData = [
    {
      icon: <FaEnvelope style={{ height: 20, width: 20 }} />,
      text: "EMAIL",
      index: 0,
      onClick: handleEmailClick,
    },
    {
      icon: <FaFacebookF style={{ height: 20, width: 20 }} />,
      text: "FACEBOOK",
      index: 1,
      onClick: handleFacebookClick,
    },
    {
      icon: <FaInstagram style={{ height: 20, width: 20 }} />,
      text: "INSTAGRAM",
      index: 2,
      onClick: handleInstagramClick,
    },
    {
      icon: <FaLinkedinIn style={{ height: 20, width: 20 }} />,
      text: "LINKEDIN",
      index: 3,
      onClick: handleLinkedinClick,
    },
    {
      icon: <FaSpotify style={{ height: 20, width: 20 }} />,
      text: "SPOTIFY",
      index: 4,
      onClick: handleSpotifyClick,
    },
    {
      icon: <FaFileAlt style={{ height: 20, width: 20 }} />,
      text: "RESUME",
      index: 5,
      onClick: handleResumeClick,
    },
  ];

  return (
    <div className="contact-page">
      <p className="small-heading" data-scroll data-scroll-speed="1.0">
        CONTACT
      </p>
      <img
        src={
          hovered !== null ? "/images/bitmoji8o.png" : "/images/bitmoji8.png"
        }
        alt="Bitmoji"
        className="bitmoji-center-contact"
        data-scroll
        data-scroll-speed="1.4"
      />
      <div
        className="button-row"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {buttonData.map(({ icon, text, index, onClick }) => (
          <div key={index} className="button-container">
    
            <button
              className="contact-button"
              onClick={() => {
                console.log(`${text} button clicked`); // Debugging
                onClick(); // Call the appropriate handler
              }}
            >
              {icon} <span className="button-title">{text}</span>
            </button>
          </div>
        ))}
      </div>
    </div>
  );
}

export default ContactPage;
