import React, { useState, useEffect, useRef } from "react";
import LocomotiveScroll from "locomotive-scroll";
import "locomotive-scroll/dist/locomotive-scroll.css";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
// Importing components and pages
import AboutPage from "./pages/AboutPage";
import WorksPage from "./pages/WorksPage";
import ContactPage from "./pages/ContactPage";
import Gallery from "./pages/ImageGallery";
import MusicPage from "./pages/MusicPage";
import InfiniteScrollText from "./pages/InfiniteScrollText";
import Model from "./components/MacbookModel";
import Blob from "./components/blob";
import SplitType from "split-type";
import Loader from "./Loader";
// Importing stylesheets
import "./App.css";
import "./pages/AboutPage.css";
import "./pages/HomePage.css";
import "./pages/ContactPage.css";
import "./fonts.css";
import InfiniteScrollText2 from "./pages/InfiniteScrollText2";
import BufferPage from "./pages/BufferPage";

// gsap.registerPlugin(ScrollTrigger);

function App() {
  // Refs for DOM elements and scroll instance
  const containerRef = useRef(null);
  const scrollInstanceRef = useRef(null);
  const textRef = useRef(null);
  const cloudTextRef = useRef(null);
  const scrollContainerRef = useRef(null);

  // State variables
  const [loading, setLoading] = useState(false);
  const [showCloudText, setShowCloudText] = useState(true);
  const [imageSrc, setImageSrc] = useState("/images/bitmoji0.png");
  const [isVisible, setIsVisible] = useState(false);
  const [showing, setShowing] = useState(false);
  const [bitmoji, setBitmoji] = useState("/images/bitmoji4.png");


  // UseEffect to handle loading state
  // useEffect(() => {
  //   const loadResources = async () => {
  //     await new Promise((resolve) => setTimeout(resolve, 5000));
  //     setLoading(false);
  //   };

  //   loadResources();
  // }, []); // Empty dependency array ensures this effect runs only once

  // if (loading) {
  //   return <Loader />; // Show loader while loading
  // }




  // Image sequence effect
  useEffect(() => {
    const startSequence = () => {
      setTimeout(() => setImageSrc("/images/bitmoji01.png"), 100);
      setTimeout(() => setImageSrc("/images/bitmoji2.png"), 200);
      setTimeout(() => setImageSrc("/images/bitmoji0.png"), 400);
    };

    // Start the sequence immediately and repeat every 3 seconds
    startSequence();
    const intervalId = setInterval(startSequence, 3000);

    return () => clearInterval(intervalId);
  }, []);

  // IntersectionObserver for showing and hiding elements based on scroll
  useEffect(() => {
    let timeout;
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setShowing(true);
          timeout = setTimeout(() => setIsVisible(true), 5000);
        } else {
          setShowing(false);
          setIsVisible(false);
          if (timeout) clearTimeout(timeout);
        }
      },
      { threshold: 0.1 }
    );

    if (cloudTextRef.current) observer.observe(cloudTextRef.current);

    return () => {
      if (cloudTextRef.current) observer.unobserve(cloudTextRef.current);
    };
  }, []);

  // GSAP animation for text reveal
  useEffect(() => {
    // Initialize SplitType
    const typeSplit = new SplitType(".animate", {
      types: "words",
      tagName: "span",
    });

    // Apply GSAP animations
    gsap.from(".animate .word", {
      opacity: 0.3, // Start with lower opacity
      duration: 0.5,
      ease: "power1.out",
      stagger: 0.1,
    });
  }, []);

  

  // Locomotive Scroll initialization and cleanup
  useEffect(() => {
    // Function to initialize LocomotiveScroll
    const initScroll = () => {
      if (containerRef.current) {
        scrollInstanceRef.current = new LocomotiveScroll({
          el: containerRef.current,
          smooth: true,
          lerp: 0.1,
          multiplier: 1,
          scrollFromAnywhere: true,
          inertia: 0.7,
          smoothMobile: true,
          mobile: {
            smooth: true,
          },
          tablet: { smooth: true },
        });
  
        // Log instance to debug
        console.log("LocomotiveScroll instance created:", scrollInstanceRef.current);
  
        // Initial update
        scrollInstanceRef.current.update();
      }
    };
  
    // Initialize scroll on mount
    initScroll();
  
    // Cleanup function to destroy LocomotiveScroll instance
    return () => {
      if (scrollInstanceRef.current) {
        scrollInstanceRef.current.destroy();
        scrollInstanceRef.current = null;
        console.log("LocomotiveScroll instance destroyed");
      }
    };
  }, []);
  

  // // Handle magnetic effect on navbar items
  // useEffect(() => {
  //   const navItems = document.querySelectorAll(".navbar ul li");

  //   function handleMouseMove(e) {
  //     const rect = this.getBoundingClientRect();
  //     const x = e.clientX - rect.left - rect.width / 2;
  //     const y = e.clientY - rect.top - rect.height / 2;
  //     this.style.transform = `translate(${x * 0.9}px, ${y * 0.9}px)`;
  //   }

  //   function handleMouseLeave() {
  //     this.style.transform = `translate(0px, 0px)`;
  //   }

  //   navItems.forEach((item) => {
  //     item.addEventListener("mousemove", handleMouseMove);
  //     item.addEventListener("mouseleave", handleMouseLeave);
  //   });

  //   return () => {
  //     navItems.forEach((item) => {
  //       item.removeEventListener("mousemove", handleMouseMove);
  //       item.removeEventListener("mouseleave", handleMouseLeave);
  //     });
  //   };
  // }, []);

 

const generateRandomPalette = () => {
  const getRandomHue = () => Math.floor(Math.random() * 360);
  const getRandomSaturation = () => Math.floor(Math.random() * 30) + 70; // 70% to 100% saturation
  const getRandomLightness = () => Math.floor(Math.random() * 30) + 40; // 40% to 70% lightness

  const hslToRgb = (h, s, l) => {
    let r, g, b;
    s /= 100;
    l /= 100;

    if (s === 0) {
      r = g = b = l; // achromatic
    } else {
      const hue2rgb = (p, q, t) => {
        if (t < 0) t += 1;
        if (t > 1) t -= 1;
        if (t < 1 / 6) return p + (q - p) * 6 * t;
        if (t < 1 / 2) return q;
        if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
        return p;
      };

      const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
      const p = 2 * l - q;
      r = hue2rgb(p, q, h + 1 / 3);
      g = hue2rgb(p, q, h);
      b = hue2rgb(p, q, h - 1 / 3);
    }

    return [Math.round(r * 255), Math.round(g * 255), Math.round(b * 255)];
  };

  const palette = Array.from({ length: 5 }, () => {
    const hue = getRandomHue();
    const saturation = getRandomSaturation();
    const lightness = getRandomLightness();
    return hslToRgb(hue / 360, saturation, lightness);
  });

  return palette;
};


// Handle mood button click to update colors based on fetched palette
const handleMoodClick = async () => {
  const palette = generateRandomPalette(); // Generate a random palette

  if (palette) {
    document.documentElement.style.setProperty("--red", `rgb(${palette[0][0]}, ${palette[0][1]}, ${palette[0][2]})`);
    document.documentElement.style.setProperty("--black", `rgb(${palette[1][0]}, ${palette[1][1]}, ${palette[1][2]})`);
    document.documentElement.style.setProperty("--gray", `rgb(${palette[2][0]}, ${palette[2][1]}, ${palette[2][2]})`);
    document.documentElement.style.setProperty("--white", `rgb(${palette[3][0]}, ${palette[3][1]}, ${palette[3][2]})`);
    document.documentElement.style.setProperty("--light-gray", `rgb(${palette[4][0]}, ${palette[4][1]}, ${palette[4][2]})`);
    document.documentElement.style.setProperty("--light-brown", `rgb(${palette[2][0]}, ${palette[2][1]}, ${palette[2][2]})`);
    document.documentElement.style.setProperty("--color1", `rgb(${palette[0][0]}, ${palette[0][1]}, ${palette[0][2]})`);
  }
};

  const [isImageVisible, setIsImageVisible] = useState(false);

  const handleNavClick = (e, sectionId) => {
    e.preventDefault(); // Prevent default link behavior

    // Show the image and trigger the animation
    setIsImageVisible(true);

    // Hide the image after animation completes (optional)
    setTimeout(() => {
      setIsImageVisible(false);
    }, 1500); // Duration of the animation (0.5s duration + some buffer)

    // Scroll to the section
    if (scrollInstanceRef.current) {
      const targetElement = document.getElementById(sectionId);
      if (targetElement) {
        scrollInstanceRef.current.scrollTo(targetElement, {
          duration: 1000,
        });
      }
    }
  };

  

  // Handle scroll events to reveal text
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const spans = entry.target.querySelectorAll("span");
          if (entry.isIntersecting) {
            spans.forEach((span) => span.classList.add("reveal"));
          } else {
            spans.forEach((span) => span.classList.remove("reveal"));
          }
        });
      },
      { root: null, threshold: 0.1 }
    );

    if (textRef.current) observer.observe(textRef.current);

    return () => {
      if (textRef.current) observer.unobserve(textRef.current);
    };
  }, []);

  // Handle bitmoji hover effects (currently commented out)
  const handleMouseEnter = (e) => {
    // setBitmoji("/images/bitmoji5.png");
  };

  const handleMouseLeave = (e) => {
    // setBitmoji("/images/bitmoji4.png");
  };

  const handleCloseClick = () => {
    setShowCloudText(false);
  };

  return (
    <div ref={containerRef} data-scroll-container>
     {!loading ? <div className="App">
        {/* HomePageStart */}
        <section data-scroll-section id="home">
          <div className="home-container">
            <nav className="navbar">
              <ul className="navbar-left">
                <li>
                  <a>STEVENSON NAGATHOTA</a>
                </li>
              </ul>
              <ul className="navbar-center">
                <li>
                  <a onClick={(e) => handleNavClick(e, "home")}>
                    HOME
                  </a>
                </li>
                <li>
                  <a  onClick={(e) => handleNavClick(e, "about")}>
                    ABOUT
                  </a>
                </li>
                <li>
                  <a
                    
                    onClick={(e) => handleNavClick(e, "contact")}
                  >
                    CONTACT
                  </a>
                </li>
              </ul>
              <ul className="navbar-right">
                <li>
                  <a onClick={handleMoodClick}>MOOD</a>
                </li>
              </ul>
            </nav>

            <div className="super-text" data-scroll data-scroll-speed="1.0">
              <span className="super">SUPERBOY</span>
              <span className="steve"> STEVE</span>
            </div>

            <img
              src={imageSrc}
              alt="Bitmoji"
              className="bitmoji-image"
              data-scroll
              data-scroll-speed="0.4"
            />
          </div>
        </section>

        {/* HomePageEnd */}

        <section data-scroll-section id="about" >
          <div className="about-page" data-scroll data-scroll-speed="1.2">
            <p
              ref={textRef}
              className="small-heading"
              data-scroll
              data-scroll-speed="0.6"
            >
              ABOUT
            </p>
            <img
              src="/images/bitmoji3.png"
              alt="Bitmoji"
              className="bitmoji-image-left"
              data-scroll
              data-scroll-speed="1.4"
            />
            <div className="about-me-text-container" >
              {/* <InfiniteScrollText2 /> */}
              <div style={{
                textAlign: "right"
              }}>
                I’m a dedicated Software Engineer with a strong foundation in
                full-stack development and UI/UX design. I excel at creating
                user-focused applications for both web and mobile platforms. My
                experience includes using advanced tools and techniques to
                enhance productivity and deliver secure, scalable solutions. I’m
                passionate about solving complex problems and continuously
                improving my skills to drive impactful technological progress.
              </div>
            </div>
            
          </div>
        </section>

        <section data-scroll-section>
          <div className="works-container">
            <p className="small-heading" data-scroll data-scroll-speed="1.5">
              WORKS
            </p>
            <div
              className="image-grid-works"
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              <div className="image-item">
                <div className="image-container">
                  <img
                    src="/images/work3.png"
                    alt="Main Image"
                    className="main-image"
                  />
                  <img
                    src="/images/peek.png"
                    alt="Peek Image"
                    className="peek-image-up"
                  />
                  <a
                    href="https://worshipdepartment.in/"
                    className="rounded-button-link"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <button className="rounded-button">
                      WORSHIP DEPARTMENT
                      <img src="/images/arrow.png" className="arrow-image" />
                    </button>
                  </a>
                </div>
              </div>

              <div className="image-item">
                <div className="image-container">
                  <img
                    src="/images/work1.png"
                    alt="Main Image"
                    className="main-image"
                  />
                  <img
                    src="/images/peek.png"
                    alt="Peek Image"
                    className="peek-image-up"
                  />
                  <a
                    href="https://stallgpt.web.app/"
                    className="rounded-button-link"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <button className="rounded-button">
                      STALL GPT
                      <img src="/images/arrow.png" className="arrow-image" />
                    </button>
                  </a>
                </div>
              </div>

              <div className="image-item">
                <div className="image-container">
                  <img
                    src="/images/work2.png"
                    alt="Main Image"
                    className="main-image"
                  />
                  <img
                    src="/images/peek.png"
                    alt="Peek Image"
                    className="peek-image-down"
                  />
                  <a
                    href="https://lifelinechurchatlanta.org/home"
                    className="rounded-button-link"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <button className="rounded-button">
                      CHURCH WEBSITE
                      <img src="/images/arrow.png" className="arrow-image" />
                    </button>
                  </a>
                </div>
              </div>

              <div className="image-item">
                <div className="image-container">
                  <img
                    src="/images/work4.png"
                    alt="Main Image"
                    className="main-image"
                  />
                  <img
                    src="/images/peek.png"
                    alt="Peek Image"
                    className="peek-image-down"
                  />
                  <a
                    href="https://www.linkedin.com/pulse/ai-generated-image-detection-using-convolutional-neural-nagathota-czigc/?trackingId=OSMNj9egSFugjQLAss4aZQ%3D%3D"
                    className="rounded-button-link"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <button className="rounded-button">
                      ARTICLES
                      <img src="/images/arrow.png" className="arrow-image" />
                    </button>
                  </a>
                </div>
              </div>
            </div>

            <div className="image-wrapper">
              {showCloudText && (
                <div
                  className={`cloud-text-container ${
                    isVisible ? "show" : "hide"
                  }`}
                  ref={cloudTextRef}
                >
                  <img
                    src="/images/cloud.png"
                    alt="Cloud Text"
                    className="cloud-text-image"
                  />
                  <button
                    className="close-button"
                    aria-label="Close"
                    onClick={handleCloseClick}
                  >
                    ×
                  </button>
                </div>
              )}
              <img
                src={bitmoji}
                alt="Bitmoji"
                className="bitmoji-image-right"
                data-scroll
                data-scroll-speed="0.7"
              />
            </div>
          </div>
        </section>

        <section data-scroll-section>
          <MusicPage />
        </section>

        {/* <section data-scroll-section>
          <Gallery />
        </section> */}

        {/* <section data-scroll-section>
          <BufferPage />
        </section> */}

        <section data-scroll-section id="contact">
          <ContactPage />
        </section>
      </div> : <Loader /> }
    </div>
  );
}

export default App;
